<template>
  <div>
    <footer class="footer" style="min-height: 160px" v-if="!isMobile">
      <div class="main" style="min-height: 160px">
        <div class="left" style="min-width: 380px">
          <img src="../../assets/img/blue_logo.png" alt="" />
          <div class="text" style="min-width: 380px">
            <p style="min-width: 380px">
              <!-- A：NO.780 Shenhua Road, Xihu District, Hangzhou City is an art space -->
              ADD. Weiyi Art Space,No.780 Shenhua Road,Xihu District,Hangzhou,China.
            </p>
            <p style="min-width: 380px">
              地址：杭州市西湖区申花路780号為一艺术空间
            </p>
            <p style="min-width: 380px">
              邮政编码：310012
            </p>
          </div>
        </div>
        <div class="right" style="min-width: 200px">
          <div class="follow" style="min-width: 200px">
            <span style="min-width: 80px">FOLLOW US</span>
          </div>
          <div class="link">
            <p style="min-width: 36px">公众号</p>
            <p style="min-width: 36px">小红书</p>
            <!-- <p style="min-width: 36px">闲鱼</p> -->
          </div>
          <div class="code">
            <img src="../../assets/img/code.png" alt="" style="min-width: 46px; min-height: 46px" />
            <img src="../../assets/img/code2.png" style="min-width: 46px; min-height: 46px;cursor: pointer;" alt="" />
            <!-- <img src="../../assets/img/code3.png" style="min-width: 46px; min-height: 46px" alt="" /> -->
          </div>
        </div>
      </div>
    </footer>
    <footer class="footer-mobile" v-else>
      <div class="img-m">
        <div class="contain" style="min-width: 250px;">
          <div class="detail">
            <p class="cn" style="min-width: 250px;">
              ADD. Weiyi Art Space,No.780 Shenhua Road,Xihu District,Hangzhou,China.
            </p>
            <p class="en" style="min-width: 250px;">地址：杭州市西湖区申花路780号為一艺术空间</p>
            <p>邮政编码：310012</p>
          </div>
          <img src="../../assets/img/blue_logo.png" alt="" class="logo" />
          <p class="follow-us">FOLLOW US</p>
          <div class="codeList">
            <div class="code">
              <span>公众号</span>
              <img src="../../assets/img/code.png" alt="" />
            </div>
            <div class="code redBook">
              <span>小红书</span>
              <img src="../../assets/img/code2.png" alt="" />
            </div>
            <!-- <div class="code">
              <span>闲鱼</span>
              <img src="../../assets/img/code3@3x.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>


<script>
export default {
  data: () => ({}),
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
  methods: {
    // gotoRedBook() {
    //   window.open("https://www.xiaohongshu.com/user/profile/5d65bf870000000001008b3d?xhsshare=CopyLink&appuid=5b7194896b58b7064e7f4297&apptime=1682318687", "_blank");
    // }
  }
};
</script>

<style lang = 'scss' scope>
.footer {
  display: block;
  background-color: #f0eadf;
  padding: 0;

  .main {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    .left {
      width: 600px;
      padding-top: 36px;

      img {
        margin-bottom: 20px;
        width: 180px;
        height: 60px;
      }

      .text {
        width: 100%;
        margin: 0px;

        p {
          margin-bottom: 0px;
          font-family: MicrosoftYaHei sans-serif;
          font-weight: 400;
          color: #333333;
          font-size: 15px;
        }
      }
    }

    .right {
      height: 240px;
      padding-top: 45px;
      margin-right: 100px;

      .follow {
        height: 28px;
        margin-bottom: 12px;

        span {
          font-size: 20px;
          // font-family: PingFangSC-Bold, PingFang SC;
          font-weight: bold;
          color: #7e5c00;
        }
      }

      .link {
        p {
          display: inline-block;
          width: 54px;
          height: 20px;
          font-size: 18px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 46px;
          margin-bottom: 20px;
        }
      }

      .code {
        img {
          width: 70px;
          height: 70px;
          margin-right: 30px;
        }
      }

      .redBook {
        cursor: pointer;
      }
    }
  }
}

.footer-mobile {
  height: calc(400 * 5.12px);
  position: relative;

  .img-m {
    width: 100%;
    height: 100%;
    background: no-repeat url("../../assets/img/footer@2x.png");
    background-size: cover;

    .contain {
      height: 100%;
      width: calc(280 * 5.12px);
      margin: 0 auto;
      padding-top: calc(24 * 5.12px);
      padding-bottom: calc(24 * 5.12px);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .detail {
        width: calc(280 * 5.12px);
        height: calc(120 * 5.12px);
        font-size: calc(12 * 5.12px);
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        text-align: center;
        line-height: calc(30 * 5.12px);
      }

      .logo {
        width: calc(105 * 5.12px);
        height: calc(52 * 5.12px);
        margin-top: calc(15 * 5.12px);
      }

      .follow-us {
        width: 100%;
        height: calc(28 * 5.12px);
        font-size: calc(20 * 5.12px);
        // font-family: PingFangSC-Bold, PingFang SC;
        font-weight: bold;
        color: #7e5c00;
        text-align: center;
        margin-top: calc(24 * 5.12px);
        // margin-bottom: calc(20 * 5.12px);
        line-height: calc(20 * 5.12px);
        z-index: 100;
      }

      .codeList {
        width: calc(280 * 5.12px);
        display: flex;
        justify-content: space-around;

        .code {
          min-width: calc(70 * 5.12px);
          display: flex;
          flex-direction: column;
          // margin-right: calc(30 * 5.12px);

          img {
            width: calc(70 * 5.12px);
            height: calc(70 * 5.12px);
          }

          &:last-child {
            margin-right: 0px;
          }

          span {
            font-size: calc(14 * 5.12px);
            text-align: center;
            margin-bottom: calc(8 * 5.12px);
          }
        }
      }

    }
  }
}
</style>