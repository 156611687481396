<template>
  <div class="bar">
    <div class="pc" v-if="!isMobile" ref="obtain">
      <div class="img">
        <!-- <div class="title"></div> -->
        <div class="search">
          <input v-model="searchTitle" type="text" placeholder="Keywords | 关键词" @keyup.enter="searchByTitle" />
          <img @click="searchByTitle" src="../../assets/img/search.png" alt="" />
        </div>
      </div>
      <div class="bar" :class="{ 'bar-top': isTop }">
        <div class="itemList">
          <div class="item" @click="goTo(home)" :class="{
              active: url.includes('/'+home) || url === html,
            }">
            Home丨主页
          </div>
          <div class="item" @click="goTo(about)" :class="{ active: url.includes('/'+about) }">
            About丨关于
          </div>
          <div class="item" @click="goTo(exhibitions)" :class="{
              active: url.includes('/'+exhibitions) || url.includes('/'+display),
            }">
            Exhibitions丨展览
          </div>
          <div class="item gallery box-show" @click="goTo(gallery)" :class="{
              active:
                url.includes('/'+gallery) ||
                url.includes('/'+ukiyo) ||
                url.includes('/'+various) ||
                title === 1,
            }">
            <div class="box-show">
              <span>Gallery丨画廊</span> <v-icon> mdi-chevron-down </v-icon>
            </div>
            <div class="nav-stacked" style="min-width: 98px">
              <ul class="ul-back">
                <li class="xiaojian"></li>
                <li class="text" @click.stop="goTo(ukiyo)" style="margin-top: 0px">
                  Ukiyo-e丨浮世绘
                </li>
                <li class="text" @click.stop="goTo(various)">Various丨其它</li>
              </ul>
            </div>
          </div>

          <div class="item" @click="goTo(artifacts)" :class="{ active: url.includes('/'+artifacts) || title === 2 }">
            Artifacts丨器物
          </div>
          <div class="item" @click="goTo(essays)" :class="{
              active: url.includes('/'+essays) || url.includes('/'+article),
            }">
            Essays丨文章
          </div>
          <div class="item" @click="goTo(contact)" :class="{ active: url.includes('/'+contact) }">
            Contact丨联系
          </div>
        </div>
      </div>
    </div>
    <div class="mobile" v-else>
      <div class="bar-m" v-if="!drawer">
        <!-- <div class="left"> -->
        <!-- <img src="../../assets/img/edit@2x.png" alt="" class="edit" @click="drawer = !drawer" /> -->
        <v-icon @click="drawer = !drawer" size="23" class="edit">mdi-menu</v-icon>
        <div class="bar-title">{{ titleM }}</div>
        <!-- </div> -->
        <img class="search-show" src="../../assets/img/searchW@2x.png" v-if="searchIsShow"
          @click="searchIsShow = !searchIsShow" />
        <transition name="fade">
          <div class="search-m" v-if="!searchIsShow">
            <input v-model="searchTitle" type="text" placeholder="Keywords | 关键词" @keyup.enter="searchByTitle" />
            <img @click="searchByTitle" class="search-img" src="../../assets/img/searchW@2x.png" alt="" />
          </div>
        </transition>
      </div>
      <div class="img-m" style="min-height: 100px" v-if="!drawer && (url.includes('/'+home) || url === html)">
        <img src="../../assets/img/m-banner.jpg" alt="" class="bg-img" />
        <!-- <img src="../../assets/img/white_logo.png" alt="" class="logo" /> -->
      </div>
      <Drawer :drawer="drawer" :title="title" @changeDrawer="(flag) => (drawer = flag)"></Drawer>
    </div>
  </div>
</template>

<script>
import Drawer from "@/components/Menu/MobileMenu.vue";
export default {
  components: {
    Drawer,
  },
  props: {
    title: {
      default: 0,
      type: Number,
    },
  },
  //TODO html 不固定确定不了主页活动状态
  data() {
    return {
      searchTitle: "",
      home: "index",
      html: null,
      about: "about",
      exhibitions: "exhibitions",
      gallery: "gallery",
      artifacts: "artifacts",
      essays: "essays",
      contact: "contact",
      ukiyo: "ukiyo",
      various: "various",
      display: "disPlay",
      article: "article",
      url: "",
      searchIsShow: true,
      drawer: false,
      screenHeight: document.body.clientHeight,
      isTop: false
    };
  },
  methods: {
    searchByTitle() {
      location.assign("./search.html?title=" + this.searchTitle);
    },
    goTo(href) {
      location.assign(`./${href}.html`);
    },
    handleScrollx() {

      if (window.pageYOffset >= 200) {
        this.isTop = true
      } else {
        this.isTop = false
      }
    },
    throttle(action) {
      let call = false;
      return function () {
        call = true;
        window.requestAnimationFrame(
          () => {
            action()
            call = false
          }
        )
      }
    }
  },
  mounted() {
    this.url = location.href;
    if (this.html === null && !this.url.includes("html")) {
      this.html = location.href;
      console.log("flag", this.url === this.html);
    }
    console.log("url", this.url);
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      window.removeEventListener('scroll', this.throttle(this.handleScrollx), false)
    } else {
      window.addEventListener('scroll', this.throttle(this.handleScrollx), true)
    }

  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    titleM() {
      if (this.url.includes('/'+this.home) || this.url === this.html) {
        return "Home | 主页";
      } else if (this.url.includes('/'+this.about)) {
        return "About | 关于";
      } else if (
        this.url.includes('/'+this.exhibitions) ||
        this.url.includes('/'+this.display)
      ) {
        return "Exhibitions | 展览";
      } else if (
        this.url.includes('/'+this.gallery) ||
        this.url.includes('/'+this.ukiyo) ||
        this.url.includes('/'+this.various) ||
        this.title === 1
      ) {
        return "Gallery | 画廊";
      } else if (
        this.url.includes('/'+this.artifacts) ||
        this.url.includes('/'+this.display) ||
        this.title === 2
      ) {
        return "Artifacts | 器物";
      } else if (
        this.url.includes('/'+this.essays) ||
        this.url.includes('/'+this.article)
      ) {
        return "Essays | 文章";
      } else if (this.url.includes('/'+this.contact)) {
        return "Contact | 联系";
      }
    },

  },
};
</script>

<style lang = 'scss' scope>
.bar {
  display: block;
  padding: 0px !important;

  .pc {
    .img {
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 200px;
      background: no-repeat url("../../assets/img/Banner.jpg");
      background-size: contain;
      z-index: 10;

      .title {
        position: absolute;
        left: 320px;
        top: 55px;
        width: 350px;
        height: 110px;
        background: no-repeat url("../../assets/img/white_logo.png");
        object-fit: cover;
        background-size: (100% 100%);
      }

      .search {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 408px;
        height: 48px;
        right: 160px;
        top: 76px;
        background: rgba(255, 255, 255, 0.7);
        border: 0px solid #979797;

        input {
          display: flex;
          margin-left: 16px;
          width: 368px;
          height: 100%;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          outline: none;
        }

        img {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }
    }

    .bar {
      background-color: rgb(235, 230, 223);
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;


      .itemList {
        width: 1200px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        // overflow: hidden;
        white-space: nowrap;

        .active {
          background-color: #cf9e18;
          color: #fff !important;

          .mdi-chevron-down::before {
            color: #fff !important;
          }
        }

        .item {
          padding: 0 24px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          cursor: pointer;
          font-size: 18px;
          font-family: serif;
          font-weight: 800;
          color: #666666;

          &:first-child {
            padding: 0 17px;
          }

          &:last-child {
            padding: 0 17px;
          }
        }

        .gallery {
          display: flex;
          align-items: center;
          position: relative;

          .v-icon {
            width: 16px;
            height: 16px;
            margin-left: 8px;
          }

          .box-show:hover+.nav-stacked {
            display: block;
          }

          .nav-stacked {
            width: 100%;
            position: absolute;
            height: 84px;
            top: 64px;
            left: 0px;
            background-color: #fff;
            z-index: 100;
            display: none;

            .ul-back {
              list-style: none;
              width: 100%;
              padding-inline-start: 0px;
              font-size: 16px;
              font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
              font-weight: 400;
              color: #999999;
              line-height: 24px;

              .text {
                margin: 12px 0;

                &:hover {
                  font-size: 16px;
                  font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
                  font-weight: 600;
                  color: #7e5c00;
                  line-height: 24px;
                }
              }

              .xiaojian {
                background-color: #fff;
                width: 10px;
                height: 10px;
                position: relative;
                left: 50%;
                top: -5px;
                margin-left: 10px;
                transform: rotate(45deg);
                border-right: 0px;
                border-bottom: 0px;
              }
            }

            &:hover {
              display: block;
            }
          }
        }
      }
    }

    .bar-top {
      position: fixed;
      top: 0px;
      z-index: 100;
    }
  }

  .mobile {
    // position: relative;

    .bar-m {
      height: calc(44 * 5.12px);
      display: flex;
      align-items: center;
      background-color: #cf9e18;

      /* position: relative; */
      .search-show {
        position: absolute;
        right: calc(18 * 5.12px);
        width: calc(12 * 5.12px);
        height: calc(12 * 5.12px);
      }

      .left {
        display: flex;
        // height: calc(25 * 5.12px);
        align-items: center;

      }

      .bar-title {
        min-width: calc(130 * 5.12px);
        // height: calc(23 * 5.12px);
        line-height: calc(44 * 5.12px);
        font-size: calc(14 * 5.12px);
        font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
        font-weight: 800;
        color: #ffffff;
        margin-left: calc(6 * 5.12px);
        margin-bottom: 0px;
      }

      .edit {
        // width: calc(14 * 5.12px);
        // height: calc(14 * 5.12px);
        color: #fff;
        margin-left: calc(12* 5.12px);
      }




      .search-m {
        width: calc(180 * 5.12px);
        height: calc(26 * 5.12px);
        border-radius: calc(5 * 5.12px);
        margin-left: calc(10 * 5.12px);
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          width: calc(103 * 5.12px);
          height: calc(16 * 5.12px);
          font-size: calc(12 * 5.12px);
          margin-left: 51.2px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          opacity: 1;
          line-height: calc(16 * 5.12px);
          outline: none;
        }

        input::-webkit-input-placeholder {
          color: rgb(255, 255, 255);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        input:-moz-placeholder {
          color: #ffffff;
        }

        input::-moz-placeholder {
          color: #ffffff;
        }

        input:-ms-input-placeholder {
          color: #ffffff;
        }

        .search-img {
          width: calc(12 * 5.12px);
          height: calc(12 * 5.12px);
          margin-right: calc(8 * 5.12px);
          cursor: pointer;
        }
      }
    }

    .img-m {
      position: relative;
      height: 512px;
      background: rgba(0, 0, 0);

      .bg-img {
        width: 100%;
        height: 100%;
        opacity: 0.9;
        filter: alpha(opacity=90);
        object-fit: cover !important;
      }

      .logo {
        position: absolute;
        top: 102px;
        left: 512px;
        z-index: 100;
        width: 860px;
        height: 307px;
      }
    }
  }
}

.v-app-bar {
  height: 264px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>