<template>
  <div class="bar">
    <div class="span">{{ title }}</div>
    <div class="more" @click="goTo" v-if="isShow">
      <div>
        <p style="min-width: 26.6px">更多</p>
        <img src="@/assets/img/Right.png" alt="#" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
    },
    href: {
      default: "./",
      type: String,
    },
    isShow: {
      default: true,
      type: Boolean,
    },
    DataType: {
      default: 0,
      type: Number,
    },
  },

  methods: {
    goTo() {
      if (this.DataType === 0) {
        location.assign(`./${this.href}.html`);
      } else {
        location.assign(`./${this.href}.html?type=${this.DataType}`);
      }
    },
  },
};
</script>

<style lang = 'scss' scoped>
.bar {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .span {
    font-size: 24px;
    /*no*/
    font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
    font-weight: 600;
    color: #7e5c00;
    margin: 0px;
  }

  .more {
    cursor: pointer;
    height: 100%;

    div {
      display: flex;
      height: 100%;
      color: #333333;
      align-items: center;
      justify-content: space-between;

      p {
        width: 40px;
        height: 27px;
        font-size: 20px;
        font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
        font-weight: 400;
        color: #333333;
        margin: 0 8px 0 0;
        line-height: 28px;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>