<template>
  <div class="list-m">
    <div class="list-item" v-for="item in list" :key="item.id">
      <!-- <img :src="item.imgUrl" alt="" /> -->
      <v-card elevation="0" @click="searchById(item.id)">
        <img class="img" :src="item.imgUrl" />
      </v-card>
      <h2 @click="searchById(item.id)">{{ item.title }}</h2>
      <p>{{ item.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: null,
      type: Array,
    },
  },
  methods: {
    searchById(id) {
      location.assign("./disPlayDetail.html?id=" + id);
    },
  },
};
</script>

<style lang = 'scss' scoped>
.list-m {
  .list-item {
    margin-bottom: calc(24 * 5.12px);

    .v-card {
      background-color: #f8f3eb;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    img {
      width: calc(315 * 5.12px);
      height: calc(156 * 5.12px);
      object-fit: cover;
    }

    h2 {
      font-size: calc(20 * 5.12px);
      font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
      margin-top: calc(16 * 5.12px);
      margin-bottom: calc(8 * 5.12px);
      color: #333;
      cursor: pointer;
    }

    p {
      max-height: calc(116 * 5.12px);
      line-height: calc(30 * 5.12px);
      font-size: calc(14 * 5.12px);
      font-family: MicrosoftYaHei;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
</style>