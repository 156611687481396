<template>
  <div class="card">
    <div class="list-good" v-for="item of list" :key="item.id">
      <v-card elevation="0" @click="searchById(item.id)" style="background-color: #f8f3eb">
        <img class="img" :src="item.imgUrl" />
      </v-card>
      <p class="card-title">{{ item.title }}</p>
      <!-- <p class="p-price">
        <span>￥</span><span class="price">{{ item.price }}</span><span>,00</span>
      </p> -->
      <div v-if="item.price == 1" class="price-title"> Price on Request｜询价 </div>
      <div v-else>
        <div class="price-title"> Reference Price｜参考价 </div>
        <span>￥</span><span class="price">{{ item.price }}</span><span>.00</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: [],
      type: Array,
    },
  },
  methods: {
    searchById(id) {
      location.assign("./detail.html?id=" + id);
    },

  },

};
</script>

<style lang = 'scss' scoped>
.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .list-good {
    width: calc(150 * 5.12px);
    /* display: flex; */
    flex-wrap: wrap;
    align-items: baseline;
    margin-right: calc(15 * 5.12px);
    margin-bottom: calc(23 * 5.12px);

    .card-title {
      width: 100%;
      height: calc(42 * 5.12px);
      font-size: calc(14 * 5.12px);
      max-height: calc(64 * 5.12px);
      font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
      font-weight: 600;
      color: #666666;
      line-height: calc(21 * 5.12px);
      margin-top: calc(5 * 5.12px);
      margin-bottom: calc(12 * 5.12px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      white-space: pre-wrap;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
    }
    .price-title{
      font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
      color: #666666;
      height: calc(40 * 5.12px);
      line-height: calc(40 * 5.12px);
      font-size: calc(11 * 5.12px);
    }
    span {
      font-size: calc(15 * 5.12px);
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #333333;

    }

    .p-price {
      line-height: calc(30 * 5.12px);
      word-break: break-word;
    }

    .price {
      font-size: calc(24 * 5.12px);
      // font-size: 24px;
    }

    .v-card {
      margin: 0px !important;
    }

    &:nth-child(2n) {
      margin-right: 0px !important;
    }

    .img {
      width: calc(150 * 5.12px);
      height: calc(224 * 5.12px);
      object-fit: cover;
    }
  }
}
</style>