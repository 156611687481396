import request from '@/utils/request'

export function getGoodList() {
  return request({
    url: "/web/index/getIndex",
    method: "get",
  })
};

export function searchGood(query) {
  return request({
    url: "/web/goods/search",
    method: "get",
    params: query
  })
}

export function searchByQueryId(query) {
  return request({
    url: "/web/goods/queryById",
    method: "get",
    params: query
  })
}