<template>
  <div class="sort">
    <h1>{{ title }}</h1>
    <v-select v-model="queryParams.orderBy" :items="selectItems" label="排序方式" dense solo @change="getList"></v-select>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      selectItems: [
        {
          text: "价格从低到高",
          value: 4,
        },
        {
          text: "价格从高到低",
          value: 3,
        },
        {
          text: "日期从新到旧",
          value: 2,
        },
        {
          text: "日期从旧到新",
          value: 1,
        },
      ],
    };
  },
};
</script>

<style lang = 'scss' scoped>
.sort {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .v-select__selections {
    margin-left: 3rem;
  }
}
</style>