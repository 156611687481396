import axios from 'axios'


const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "http://116.62.235.9:18000",
  // 超时
  timeout: 10000,
})

export default service