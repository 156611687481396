import request from '@/utils/request'

export function getCategory(query) {
  return request({
    url: "/web/category/allCategoryList",
    method: "get",
    params: query
  })
};

export function getArtist(query) {
  return request({
    url: "/web/artist/allArtistList",
    method: "get",
    params: query
  })
}

export function getLabel(query) {
  return request({
    url: "/web/label/allLabelList",
    method: "get",
    params: query
  })
}

export function getRecommend(query) {
  return request({
    url: "/web/goods/recommend",
    method: "get",
    params: query
  })
}

//文章字典
export function getByType() {
  return request({
    url: "/web/dict/getByType",
    method: "get"
  })
}