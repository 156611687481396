<template>
  <div>
    <div class="list" v-for="item of list" :key="item.id">
      <v-card elevation="0" @click="searchById(item.id)">
        <img class="img" :src="item.imgUrl" />
      </v-card>
      <div class="container">
        <h2 @click="searchById(item.id)">{{ item.title }}</h2>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: [],
      type: Array,
    },
  },
  methods: {
    searchById(id) {
      location.assign("./disPlayDetail.html?id=" + id);
    },
  },
};
</script>

<style lang = 'scss' scoped>
.list {
  width: 100%;
  display: flex;
  margin-top: 18px;

  &:nth-child(3n) {
    margin-bottom: 40px;
  }

  .v-card {
    background-color: #f8f3eb;
  }

  .img {
    width: 420px;
    height: 240px;
    object-fit: cover;
  }

  .container {
    width: 732px;
    margin: auto 0;
    margin-left: 25px;

    h2 {
      font-size: 24px;
      font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      margin-bottom: 21px;
      cursor: pointer;
    }

    p {
      font-size: 15px;
      font-family: MicrosoftYaHei;
      // font-weight: 100;
      color: #333333;
      line-height: 30px;
      width: 732px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>