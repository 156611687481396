<template>
  <div class="menu" v-if="drawer">
    <div class="drawer">
      <div class="bar-m-d">
        <!-- <div class="left" style="display: flex"> -->
        <v-icon class="edit" @click="$emit('changeDrawer', false)" size="23">mdi-menu</v-icon>
        <span class="bar-title">Menu</span>
        <!-- </div> -->
      </div>
      <div class="search-m-b">
        <div class="search-i">
          <input v-model="searchTitle" type="text" placeholder="Keywords | 关键词" @keyup.enter="searchByTitle" />
          <img class="search-img" @click="searchByTitle" src="../../assets/img/searchW@2x.png" alt="" />
        </div>
      </div>
      <div class="list-m">
        <div class="item" @click="goTo(home)" :class="{
          active: url.includes(home) || url === home2,
        }">
          <span> Home丨主页 </span>
        </div>
        <div class="item" @click="goTo(about)" :class="{ active: url.includes(about) }">
          <span> About丨关于 </span>
        </div>
        <div class="item" @click="goTo(exhibitions)" :class="{
          active:
            url.includes(exhibitions) || url.includes(display),
        }">
          <span> Exhibitions丨展览 </span>
        </div>
        <div class="item" :class="{
          activeGallery: menuIsShow,
          active:
            url.includes(gallery) ||
            url.includes(ukiyo) ||
            url.includes(various) ||
            title === 1,
        }">
          <div class="box-show">
            <span @click="goTo(gallery)">Gallery丨画廊</span>
            <div class="icon" @click="changeActive">
              <v-icon size="25"> mdi-chevron-down </v-icon>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="nav-stacked" v-if="menuIsShow">
            <div class="item" :class="{
              itemActive: url.includes(ukiyo)
            }" @click.stop="goTo(ukiyo)">
              <span>Ukiyo-e丨浮世绘</span>
            </div>
            <div class="item" :class="{
              itemActive: url.includes(various)
            }" @click.stop="goTo(various)">
              <span>Various丨其它</span>
            </div>
          </div>
        </transition>

        <div class="item" @click="goTo(artifacts)" :class="{ active: url.includes(artifacts) || title === 2 }">
          <span> Artifacts丨器物 </span>
        </div>
        <div class="item" @click="goTo(essays)" :class="{
          active: url.includes(essays) || url.includes(article),
        }">
          <span> Essays丨文章 </span>
        </div>
        <div class="item" @click="goTo(contact)" :class="{ active: url.includes(contact) }">
          <span> Contact丨联系 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    drawer: {
      default: false,
      type: Boolean,
    },
    title: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      menuIsShow: false,
      home: "index",
      home2: null,
      about: "about",
      exhibitions: "exhibitions",
      gallery: "gallery.html",
      artifacts: "artifacts",
      essays: "essays",
      contact: "contact",
      ukiyo: "ukiyo",
      various: "various",
      display: "disPlay",
      article: "article",
      searchTitle: "",
      url: "",
    };
  },
  methods: {
    searchByTitle() {
      location.assign("./search.html?title=" + this.searchTitle);
    },
    goTo(href) {
      if(href.includes('html')){
        location.assign(`./${href}`)
      }else{
        location.assign(`./${href}.html`);
      }
    },
    changeActive() {
      this.menuIsShow = !this.menuIsShow;
    },
  },
  mounted() {
    this.url = location.href;
    if (this.url.includes("ukiyo") || this.url.includes("various")) {
      this.menuIsShow = true
    }
    if (this.home2 === null && !this.url.includes("html")) {
      this.home2 = location.href;
      console.log("flag", this.url === this.home2);
    }
  },
};
</script>

<style lang = 'scss' scoped>
.drawer {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #f2e1c7;

  .bar-m-d {
    height: calc(44 * 5.12px);
    display: flex;
    align-items: center;
    background-color: #f2e1c7;

    /* position: relative; */
    // .left {
    //   display: flex;
    //   align-items: center;
    //   // margin-left: calc(16 * 5.12px);
    // }

    .search-show {
      position: absolute;
      right: calc(18 * 5.12px);
      width: calc(12 * 5.12px);
      height: calc(12 * 5.12px);
    }

    .edit {
      // width: calc(14 * 5.12px);
      // height: calc(14 * 5.12px);
      margin-left: calc(12 * 5.12px);
    }

    .bar-title {
      display: inline-block;
      width: calc(130 * 5.12px);
      font-size: calc(14 * 5.12px);
      line-height: calc(44 * 5.12px);
      font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
      font-weight: 800;
      color: #333;
      margin-left: calc(6 * 5.12px);
      // line-height: calc(44* 5.12px);
    }

    .v-icon {
      width: calc(16 * 5.12px);
      height: calc(16 * 5.12px);
    }
  }

  .search-m-b {
    height: calc(44 * 5.12px);
    display: flex;
    align-items: center;

    .search-i {
      width: calc(343 * 5.12px);
      height: calc(30 * 5.12px);
      margin: calc(10 * 5.12px) calc(16 * 5.12px);
      border-radius: calc(5 * 5.12px);
      background-color: rgba(0, 0, 0, 0.1);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: calc(360 * 5.12px);
        height: calc(16 * 5.12px);
        font-size: calc(12 * 5.12px);
        margin-left: 51.2px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        opacity: 1;
        line-height: calc(16 * 5.12px);
        outline: none;
      }

      input::-webkit-input-placeholder {
        color: rgb(255, 255, 255);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }

      input:-moz-placeholder {
        color: #ffffff;
      }

      input::-moz-placeholder {
        color: #ffffff;
      }

      input:-ms-input-placeholder {
        color: #ffffff;
      }

      .search-img {
        width: calc(12 * 5.12px);
        height: calc(12 * 5.12px);
        margin-right: calc(8 * 5.12px);
        cursor: pointer;
      }
    }
  }

  .list-m {
    .nav-stacked {
      .item {
        background-color: #fff;
        border-bottom: 1px solid #f2e1c7;

        span {
          margin-left: calc(32 * 5.12px);
        }
      }

      .itemActive {
        background-color: #cf9e18;

        span {
          color: #fff !important;
        }
      }
    }

    .item {
      display: flex;
      align-items: center;
      height: calc(44 * 5.12px);
      border-top: 1px solid white;

      &:last-child {
        border-bottom: 1px solid white;
      }

      span {
        font-size: calc(14 * 5.12px);
        font-family: SourceHanSerifCN-Medium, SourceHanSerifCN;
        font-weight: 500;
        color: #666666;
        line-height: calc(20 * 5.12px);
        margin-left: calc(16 * 5.12px);
      }

      .box-show {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: block;
          flex: 1;
          height: 100%;
          width: 100%;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid white;
          border-right: 1px solid white;
          width: calc(44 * 5.12px);
          height: calc(44 * 5.12px);
        }
      }
    }

    .active {
      background-color: #cf9e18;

      span {
        color: #fff;
      }
    }

    .activeGallery {
      background-color: #fff;
      border-bottom: 1px solid #f2e1c7;

      .icon {
        transform: rotate(180deg);
      }

      span {
        color: #666;
      }
    }
  }
}
</style>