<template>
  <div class="main-m">
    <v-breadcrumbs :items="items" large></v-breadcrumbs>
    <v-divider></v-divider>
    <div class="menu-m">
      <div class="menu-item">
        <span class="subject">类别丨Subject:</span>
        <v-tabs grow background-color="#f8f3eb" color="#999" hide-slider active-class="active-tab" height="26">
          <v-tab @click="searchByCategory(undefined)">全部 | All</v-tab>
          <v-tab v-for="item in categoryNameList" :key="item.id" @click="searchByCategory(item.id)">{{
            item.categoryName[0] }}|{{ item.categoryName[1] }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="menu-item">
        <span class="artist">艺术家丨Artist:</span>
        <v-tabs grow background-color="#f8f3eb" color="#999" center-active hide-slider active-class="active-tab"
          height="26">
          <v-tab @click="searchByArtist(undefined)">全部 | All</v-tab>
          <v-tab v-for="item in artistList" :key="item.id" @click="searchByArtist(item.id)">
            {{ item.artistName[0] }} | {{ item.artistName[1] }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="menu-item">
        <span class="artist">标签丨Label:</span>
        <v-tabs grow background-color="#f8f3eb" color="#999" center-active hide-slider active-class="active-tab"
          height="26">
          <v-tab v-for="item in labelList" :key="item.id">
            <v-layout><v-checkbox color="#7e5c00" v-model="labelIds" :value="item.id"
                style="min-width: 97px; min-height: 10px" @change="getList">
                <template v-slot:label>
                  <span class="checkboxLabel">{{ item.labelName[0] }} | {{ item.labelName[1] }}</span>
                </template>
              </v-checkbox>
            </v-layout>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="sort-m">
      <h1>{{ title }}</h1>
      <v-select v-model="queryParams.orderBy" :items="selectItems" label="排序方式" solo full-width loader-height="28"
        height="28" @change="getList">
        <template v-slot:label>
          <span class="SortLabel">排序方式</span>
        </template>
      </v-select>
    </div>
    <div class="list">
      <MobileCard :list="goodList"></MobileCard>
    </div>
    <div class="text-center">
      <v-pagination v-model="queryParams.pageNum" :length="pageLength" :total-visible="8" @input="getList"></v-pagination>
      <span>跳至</span>
      <v-text-field full-width height="20" solo @keyup.enter="changePageNum" v-model="pageNumber">
        <template v-slot:label>
          <div></div>
        </template>
      </v-text-field>
      <span>页</span>
    </div>
  </div>
</template>

<script>
import SortTitle from "@/components/TitleBar/mobileSort.vue";
import MobileCard from "@/components/goodCard/mobileGood.vue";
import { searchGood } from "@/api/home";
import { getLabel } from "@/api/public";

export default {
  components: {
    SortTitle,
    MobileCard,
  },
  props: {
    artistList: {
      default: [],
      type: Array,
    },
    categoryNameList: {
      default: [],
      type: Array,
    },
    title: {
      default: "",
      type: String,
    },
    selectItems: {
      default: [],
      type: Array,
    },
  },
  data() {
    return {
      detail: "",
      labelIds: [],
      goodList: [],
      pageLength: null,
      pageNumber: null,
      queryParams: {
        pageSize: 8,
        pageNum: 1,
        dataType: 1,
        type: 1,
        orderBy: 1,
        categoryOneId: null,
        categoryTwoId: null,
        categoryThreeId: null,
        labelIds: "",
        artistId: null,
      },
      labelList: [],
    };
  },
  methods: {
    changePageNum() {
      console.log(this.pageNumber);
      this.queryParams.pageNum = Number(this.pageNumber);
      this.getList();
    },
    thousands(goodList) {
      goodList.map((item) => {
        let str = item.price.toString();
        let reg =
          str.indexOf(".") > -1
            ? /(\d)(?=(\d{3})+\.)/g
            : /(\d)(?=(?:\d{3})+$)/g;
        item.price = str.replace(reg, "$1,");
      });
    },

    searchByArtist(id) {
      this.queryParams.artistId = id;
      if (id instanceof Object) {
        this.queryParams.artistId = null;
      }
      this.getList();
    },
    searchByCategory(categoryId) {
      this.queryParams.categoryOneId = categoryId;
      if (categoryId instanceof Object) {
        this.queryParams.categoryOneId = null;
      }
      this.getList();
    },
    getList() {
      this.queryParams.labelIds = this.labelIds.join(",");
      searchGood(this.queryParams).then((res) => {
        this.loading = true;
        this.goodList = res.data.data.list;
        console.log("mgoodList", res.data.data);
        this.thousands(this.goodList);
        this.pageLength = res.data.data.pages;
        this.loading = false;
      });
    },
    initCheckBox() {
      console.log("this.labelList", this.labelList);
      // this.labelList.forEach((item) => {
      //   console.log("item", item);
      //   this.labelIds.push(item.id);
      // });
    },
    //根据竖线拆开
    split(list, name) {
      list = list.map((item) => {
        let arr;
        arr = item[name]?.split("|");
        item[name] = arr;
        return list;
      });
    },
    init() {
      this.initCheckBox();
    },
  },
  computed: {
    items() {
      return [
        {
          text: "画廊",
          disabled: false,
          href: "gallery.html",
        },
        {
          text: this.detail,
          disabled: true,
        },
      ];
    },
  },
  mounted() {
    if (location.href.includes("ukiyo")) {
      this.detail = "浮世绘";
      getLabel({ type: 1 }).then((res) => {
        this.labelList = res.data.data;
        // this.labelList.forEach((item) => {
        //   this.labelIds.push(item.id);
        // });
        this.split(this.labelList, "labelName");
        this.queryParams.labelIds = this.labelIds.join(",");
        this.getList();
      });
    } else if (location.href.includes("various")) {
      this.queryParams.type = 2;
      getLabel({ type: 2 }).then((res) => {
        this.labelList = res.data.data;
        // this.labelList.forEach((item) => {
        //   this.labelIds.push(item.id);
        // });
        this.split(this.labelList, "labelName");
        this.queryParams.labelIds = this.labelIds.join(",");
        this.getList();
      });
      this.detail = "其他";
    }
    this.init();
  },
};
</script>

<style lang = 'scss' scoped>
.main-m {
  width: calc(315 * 5.12px);
  margin: 0 auto;
  margin-top: calc(16 * 5.12px);

  .v-breadcrumbs {
    margin-bottom: calc(16 * 5.12px);

    ::v-deep li {
      font-size: calc(14 * 5.12px);

      .v-breadcrumbs__item {
        font-size: calc(14 * 5.12px);
        color: #999;
      }

      &:last-child {
        .v-breadcrumbs__item {
          color: #333;
        }
      }
    }
  }

  .menu-m {
    .menu-item {
      display: flex;
      align-items: center;
      margin-top: calc(16 * 5.12px);


      span {
        display: inline-block;
        min-width: calc(91 * 5.12px);
        font-size: calc(12 * 5.12px);
        height: calc(16 * 5.12px);
        font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
        font-weight: 400;
        color: #333333;
        line-height: calc(16 * 5.12px);
      }

      .subject {
        width: calc(91 * 5.12px);
      }
    }

    .v-tabs {
      height: calc(16 * 5.12px);
      /* flex: none; */
      max-width: calc(250 * 5.12px);
    }

    .v-tab {

      min-width: calc(80 * 5.12px);
      margin-right: calc(15 * 5.12px);
      height: calc(16 * 5.12px);
      font-size: calc(12 * 5.12px);
      font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
      font-weight: 400;
      color: #999;
      line-height: calc(16 * 5.12px);
      text-transform: none;

      .checkboxLabel {
        top: calc(1 * 5.12px);
        left: calc(5 * 5.12px);
        position: relative;
      }

      ::v-deep .v-input--selection-controls__input {

        .mdi-checkbox-marked,
        .mdi-checkbox-blank-outline {
          font-size: calc(12 * 5.12px);
        }
      }

      ::v-deep .v-input {
        font-size: calc(12 * 5.12px) !important;
      }
    }

    .active-tab {
      /* height: calc(18 * 5.12px);
        line-height: calc(18 * 5.12px); */
      color: #7e5c00;
      font-weight: bold;
      background-color: #f8f3eb;
    }
  }

  .sort-m {
    height: calc(28 * 5.12px);
    margin-top: calc(24 * 5.12px);
    margin-bottom: calc(24 * 5.12px);
    display: flex;
    align-items: center;

    h1 {
      width: calc(207 * 5.12px);
      font-size: calc(17 * 5.12px);
      font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
      font-weight: 600;
      color: #7e5c00;
      line-height: calc(20 * 5.12px);
    }

    .v-input {
      width: calc(108 * 5.12px);
      height: calc(28 * 5.12px);
    }

    .v-input__control {
      height: 100% !important;
    }

    .v-text-field__details {
      display: none;
    }

    .v-input__slot {
      // display: inline-block;
      height: 100%;
    }

    ::v-deep .v-select__selections {
      height: calc(28 * 5.12px);

      .v-select__selection {
        margin-left: calc(10 * 5.12px);
        line-height: calc(28 * 5.12px);
        font-size: calc(12 * 5.12px);
      }

    }

    ::v-deep .v-input__append-inner {
      .v-input__icon {
        margin-right: calc(2 * 5.12px);

        .v-icon {
          font-size: calc(14 * 5.12px);
        }
      }
    }
  }


  .text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(48 * 5.12px);

    span {
      font-size: calc(14 * 5.12px);
    }

    .v-input {
      width: calc(30 * 5.12px);
      height: calc(20 * 5.12px);
      margin: 0 calc(5 * 5.12px);
      flex: none;

      ::v-deep input {
        max-height: calc(20 * 5.12px);
        text-align: center;
      }
    }

    .v-input__control {
      width: calc(30 * 5.12px);
      height: calc(20 * 5.12px);
      min-height: calc(20 * 5.12px);
    }

    .v-pagination__item {
      height: calc(12 * 5.12px);
    }
    ::v-deep .v-pagination__more{
      height: calc(20 * 5.12px);
      width: calc(20 * 5.12px);
      font-size: calc(14 * 5.12px);
    }

    .v-text-field {
      font-size: calc(14 * 5.12px);
      line-height: calc(20 * 5.12px);
    }

    ::v-deep .v-pagination {
      li {
        width: calc(20 * 5.12px);
        height: calc(20 * 5.12px);
        margin-right: calc(8 * 5.12px);

        button {
          width: calc(20 * 5.12px);
          height: calc(20 * 5.12px);
          font-size: calc(12 * 5.12px);

          .v-icon {
            font-size: calc(16 * 5.12px);
          }
        }
      }
    }
  }
}
</style>