<template>
  <div class="card">
    <div class="list-good" v-for="item of list" :key="item.id">
      <v-card elevation="0" @click="searchById(item.id)" style="background-color: #f8f3eb">
        <img class="img" :src="item.imgUrl" />
      </v-card>
      <p class="card-title">{{ item.title }}</p>
      <!-- <p class="price">
        <span>￥</span><span class="price">{{ item.price }}</span><span>,00</span>
      </p> -->
      <div v-if="item.price == 1" class="price-title"> Price on Request｜询价 </div>

      <div class="price" v-else>
        <div class="price-title"> Reference Price｜参考价 </div>
        <span>￥</span><span class="price">{{ item.price }}</span><span>.00</span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: [],
      type: Array,
    },
  },
  methods: {
    searchById(id) {
      location.assign("./detail.html?id=" + id);
    },
  },
};
</script>

<style lang = 'scss' scoped>
.card {
  width: 941px;
  display: flex;
  flex-wrap: wrap;

  .list-good {
    /* display: flex; */
    flex-wrap: wrap;
    width: 280px !important;
    height: 503px;
    margin: 0px 40px 60px 0px !important;

    .card-title {
      width: 100%;
      font-size: 15px;
      line-height: 23px;
      height: 47px;
      font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
      font-weight: 600;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      white-space: pre-wrap;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .price-title{
      font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
    }
    .price {
      .price {
        font-size: 24px;
        font-family: Roboto Regular Regular;
      }
    }

    p {
      font-size: 15px;
      font-family: Roboto-Regular, Roboto;
      // font-weight: 100;
      color: #333333;
      line-height: 30px;
      word-break: break-word;
      margin-bottom: 10px;

      span {
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
        margin-right: 5px;
      }
    }

    .v-card {
      margin: 0px !important;
    }

    &:nth-child(3n) {
      margin-right: 0px !important;
    }

    .img {
      width: 280px;
      height: 394px;
      object-fit: cover;
    }
  }
}
</style>