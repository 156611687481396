<template>
  <div class="m-bar">
    <div class="span">{{ title }}</div>
    <div class="more" @click="goTo" v-if="isShow">
      <div class="a">
        <p style="min-width: 25px; text-align: center">更多</p>
        <img src="@/assets/img/Right@2x.png" alt="#" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
    },
    href: {
      default: "./",
      type: String,
    },
    isShow: {
      default: true,
      type: Boolean,
    },
    DataType: {
      default: 0,
      type: Number,
    },
  },

  methods: {
    goTo() {
      if (this.DataType === 0) {
        location.assign(`./${this.href}.html`);
      } else {
        location.assign(`./${this.href}.html?type=${this.DataType}`);
      }
    },
  },
};
</script>

<style lang = 'scss' scoped>
.m-bar {
  width: 100%;
  height: calc(20 * 5.12px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(32 * 5.12px);
  margin-bottom: calc(16 * 5.12px);

  .span {
    font-size: calc(15 * 5.12px);
    font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
    font-weight: 600;
    color: #7e5c00;
    margin: 0px;
  }

  .more {
    cursor: pointer;
    height: 100%;

    .a {
      display: flex;
      height: 100%;
      color: #333333;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: center;

      p {
        min-width: calc(24 * 5.12px);
        height: calc(14 * 5.12px);
        font-size: calc(12 * 5.12px);
        font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
        font-weight: 400;
        color: #333333;
        line-height: calc(15 * 5.12px);
        margin: 0 calc(2 * 5.12px) 0 0;
      }

      img {
        width: calc(14 * 5.12px);
        height: calc(14 * 5.12px);
      }
    }
  }
}
</style>